@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}
.fade {
    transition: opacity 0.5s ease-in-out;
}

.loading {
    animation: fadeInParts 1.5s forwards;
}

@keyframes fadeInParts {
    0% {
        opacity: 0;
        clip-path: inset(50% 50% 50% 50%);
    }

    25% {
        clip-path: inset(25% 25% 25% 25%);
    }

    50% {
        clip-path: inset(10% 10% 10% 10%);
    }

    75% {
        clip-path: inset(5% 5% 5% 5%);
    }

    100% {
        opacity: 1;
        clip-path: inset(0 0 0 0);
    }
}