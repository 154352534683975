.navbar-section {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: white;
    
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-text {
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
}

@media screen and (max-width: 900px) {
    .navbar-text {
        display: none;
    }
}
.navbar-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-sign {
    color: hsl(0, 0%, 0%);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
}

.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 42px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.navbar-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.navbar-items>li {
    list-style-type: none;
}

.navbar-links {
    text-decoration: none;
    color: black;
    font-size: 18px;
    letter-spacing: 0.8px;
}

.navbar-links:hover {
    color: #0cc2ea;
}

.navbar-btn {
    margin-right: 0px;
    padding: 9px 15px;
    color: black;
    border: 1px solid  black;
    border-radius: 28px;
    outline: transparent;
    background-color: white;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0.11px;
    cursor: pointer;
    transition: all 0.4s ease;
}

.navbar-btn:hover {
    color: black;
    background-color: blanchedalmond;
    border: 1px solid black;
}

/* Hamburger Icon */
.mobile-nav {
    display: none;
}

.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.hamb-icon:hover {
    color: #0cc2ea;
}

/* Mobile Navbar */
.mobile-navbar {
    margin-top: 82px;
    margin-left: 15px;
    margin-right: 19px;
    display: flex;
    flex-direction: column;
    width: 90%;
    color: black;
   
    border-radius: 10px;
        border: 2px solid black;
    height: 68vh;
    position: fixed;
    top: 0;
    right: -100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: whitesmoke;
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: right 0.5s ease-in-out;
}

.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}

.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}

.open-nav {
    right: 0;
}

.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    gap: 14px;
    text-align: center;
}

.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: bold;
    letter-spacing: 0.8px;
    transition: color 0.3s ease-in-out;
}

.mobile-navbar-links li a:hover {
    color: #0cc2ea;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 130px;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown-content.show {
    display: block;
}

.dropdown:hover .dropdown-content {
    display: block;
    
}

@media screen and (max-width: 900px) {
.navbar-section {
    position: sticky;}
    .navbar-btn,
    .navbar-items {
        display: none;
    }

    .mobile-nav {
        display: block;
    }

    .navbar-sign {
        font-size: 11px;
    }

    .dropdown-content {
        background-color: white;
        z-index: 1000;
      
        box-shadow: none;
        min-width: auto;
    }
                .dropdown:hover.hide-dropdown {
            display: none;
            position: relative;
        }
}