.about-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

/* Image */
.project-image-contentleft {
    width: 30%;
    
    max-width: 100%;
   min-height: 10%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.project-image-contentright {
    width: 25%;

    max-width: 100%;
    min-height: 10%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.about-image1 {
    width: 70%;
    height: auto;
}

/* Text */
.about-text-content {
    width: 50%;
}

.project-title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 40px;
    font-weight: bold;
}

.project-title>span {
    position: relative;
    @media screen and (max-width: 1000px){
      font-size: 21px; 
    margin-top: 40px;
   
    display: flex;
    justify-content: center;
    }
}

.project-title span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f7ef5b;
    border-radius: 8px;
    left: 0;
    bottom: -12px;

 
}

.project-title2 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 40px;
    font-weight: bold;
}

.project-title2>span {
    position: relative;

    @media screen and (max-width: 1000px) {
        font-size: 21px;
       

        display: flex;
        justify-content: center;
    }
}

.project-title2 span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f7ef5b;
    border-radius: 8px;
    left: 0;
    bottom: -12px;


}

.about-description {
    margin: 32px 0;
    color: #4d4b4b;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.7rem;
}

.about-text-title {
    margin: 0 0 24px 0;
    color: #4d4b4b;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}

/* Steps */
.about-text-step {
    margin: 16px 0;
}

.about-text-sTitle {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .7px;
}

.about-text-description {
    margin: 8px 24px;
    color: #4d4b4b;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: .7px;
    line-height: 1.6rem;
}

.fa-icon {
    color: black;
}

/* Responsive */
@media screen and (max-width: 1000px) {
    .about-section {
        display: block;
    }

    .about-image-content {
        width: 100%;
        max-width: 100%;
        margin: 0 0 32px 0;
    }

    .about-image1 {
        width: 100%;
        height: 100%;
    }

    .about-text-content {
        width: 100%;
    }
}